import React from 'react'
export async function loader({ request }) {
 
  return {};
}

export function Index() {
  return (
    <div>
      
    </div>
  )
}
