import React from 'react'

export async function loader({ request }) {
 
  return {};
}

export function Edit() {
  return (
    <div>
      
    </div>
  )
}
